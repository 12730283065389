import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useColor } from '@xstyled/styled-components';
import { captureException, captureMessage } from '@sentry/nextjs';
import getCartProductData from 'utils/getCartProductData';
import getProductImage from 'utils/getProductImage';
import getFormattedPrice from 'utils/getFormattedPrice';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import Button from '@/UI/Button';
import { Close } from '@/UI/Icons';
import { ProductSection, CartProduct, CartProductInfo } from './styles';
import Cookies from 'universal-cookie';
import { CART_ID } from 'global/constants';
import { useLazyQuery } from '@apollo/client';
import { CART } from 'graphql/queries';

export default function Products({ cart: propCart, updateItem }) {
  const mainTeal = useColor('teal-100');
  const [cart, setCart] = useState(propCart);
  const [isLoading, setIsLoading] = useState(false);
  const cookies = new Cookies();
  const cartId = cookies.get(CART_ID);

  // Direct cart query for emergencies
  const [getCart, { loading: cartLoading, error: cartError }] = useLazyQuery(CART, {
    variables: { id: cartId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.cart) {
        setCart(data.cart);
        captureMessage('Products emergency cart fetch success', {
          level: 'info',
          extra: {
            cartId,
            hasItems: Boolean(data.cart?.items?.length),
            itemCount: data.cart?.items?.length || 0
          }
        });
      }
      setIsLoading(false);
    },
    onError: (error) => {
      captureException(error, {
        extra: { action: 'Products emergency cart fetch', cartId }
      });
      setIsLoading(false);
    }
  });

  // Emergency direct refresh
  const emergencyRefresh = () => {
    if (cartId) {
      setIsLoading(true);
      getCart();
      captureMessage('Products emergency refresh triggered', {
        level: 'info',
        extra: { cartId }
      });
    }
  };

  // Update local cart when prop changes
  useEffect(() => {
    if (propCart) {
      setCart(propCart);
    }
  }, [propCart]);

  // Handle case when cart is null but ID exists
  useEffect(() => {
    if (!cart && cartId && !isLoading) {
      captureMessage('Products component with null cart but ID exists', {
        level: 'warning',
        extra: { cartId }
      });
      
      // Try immediate emergency refresh
      emergencyRefresh();
    }
  }, [cart, cartId, isLoading]);

  // Log cart state to Sentry for debugging
  useEffect(() => {
    try {
      captureMessage('Products component rendered', {
        level: 'info',
        extra: {
          propCartExists: Boolean(propCart),
          localCartExists: Boolean(cart),
          hasItems: Boolean(cart?.items),
          itemCount: cart?.items?.length || 0,
          cartId,
          isLoading: isLoading || cartLoading,
          timestamp: new Date().toISOString()
        }
      });
      
      // Check for potential issues
      if (!cart) {
        captureMessage('Cart is null or undefined', { 
          level: 'warning',
          extra: { cartId, usingFallback: Boolean(propCart !== cart) }
        });
      } else if (!cart.items) {
        captureMessage('Cart items array is null or undefined', { 
          level: 'warning',
          extra: { cartId }
        });
      }
    } catch (error) {
      console.error('Error in cart monitoring:', error);
      captureException(error);
    }
  }, [cart, propCart, cartId, isLoading, cartLoading]);

  if (isLoading || cartLoading) {
    return (
      <ProductSection>
        <p>Loading cart...</p>
      </ProductSection>
    );
  }

  return (
    <ProductSection>
      {cart?.items?.length > 0 ? (
        cart.items.map(cartItem => {
          try {
            if (!cartItem || !cartItem.product) {
              console.warn('Invalid cart item encountered:', cartItem);
              return null;
            }
            
            const { price, selectedVariants } = getCartProductData(cartItem);
            const {
              name,
              price_range,
              image: mainImage,
              media_gallery,
              uid,
              sku
            } = cartItem?.product;

            // Get product image
            let variableUid;
            let image = getProductImage({ mainImage, gallery: media_gallery }) || {
              url: 'https://do.baltbaby.com/media/logo/default/Asset_1_4x.png', // Placeholder image URL
              label: 'Product Image'
            };
            
            if (cartItem?.configured_variant) {
              variableUid = cartItem?.configured_variant?.uid;
              if (cartItem?.configured_variant?.image) {
                image = cartItem?.configured_variant?.image;
              }
            }
            
            // Get original price in case of discount
            const originalPrice =
              price_range?.minimum_price?.discount?.percent_off > 0 &&
              getFormattedPrice(price_range?.minimum_price?.regular_price?.value);
            
            return (
              <CartProduct spaceX="1rem" key={variableUid ? variableUid : uid}>
                <Image
                  src={image?.url}
                  alt={image?.label || 'Product Image'}
                  width={90}
                  height={90}
                  onError={(e) => {
                    e.currentTarget.src = 'https://do.baltbaby.com/media/logo/default/Asset_1_4x.png'; // Fallback on error
                  }}
                />
                <CartProductInfo>
                  <p>{name || 'Product name not available'}</p>
                  {selectedVariants && (
                    <span className="variant">
                      {selectedVariants.join(' - ')}
                    </span>
                  )}
                  <FlexBox align="center" spaceX={10}>
                    <span className="price">{price || 'Price not available'}</span>
                    {originalPrice && (
                      <span className="originalPrice">{originalPrice}</span>
                    )}
                  </FlexBox>
                  <Button
                    p="0.25rem"
                    w="100px"
                    border={`1px solid ${mainTeal}`}
                    borderRadius="18px"
                  >
                    <span
                      role="button"
                      aria-label="Increment product amount"
                      id="plus"
                      onClick={() => updateItem(cartItem, cartItem.quantity + 1)}
                    >
                      +
                    </span>
                    {cartItem.quantity}
                    <span
                      role="button"
                      aria-label="Decrement product amount"
                      id="minus"
                      onClick={() => updateItem(cartItem, cartItem.quantity - 1)}
                    >
                      -
                    </span>
                  </Button>
                </CartProductInfo>
                <span
                  role="button"
                  aria-label="Remove item from cart"
                  onClick={() => updateItem(cartItem, 0)}
                >
                  <Close />
                </span>
              </CartProduct>
            );
          } catch (error) {
            captureException(error, {
              extra: { 
                cartItemData: cartItem,
                errorLocation: 'Cart item render'
              }
            });
            return null; // Skip rendering this item if there's an error
          }
        })
      ) : (
        <div>
          <p>No products in cart.</p>
          {!isLoading && (
            <Button 
              onClick={emergencyRefresh}
              p="0.5rem 1rem"
              border={`1px solid ${mainTeal}`}
              borderRadius="18px"
              mt="1rem"
            >
              Refresh Cart
            </Button>
          )}
        </div>
      )}
    </ProductSection>
  );
}